<template>
    <div class="ui stackable grid m-0">
        <div class="row border-bottom">
            <div class="eight wide left aligned column">
                <h1 class="ui grey header"> Nouveau Honoraire </h1>
            </div>
            <div class="eight wide right aligned column">
                <div class="ui primary tiny button" @click="save">
                    <i class="check circle icon"></i>
                    Enregistrer
                </div>
                <div class="ui red button" v-if="isEdit" @click="remove">
                    <i class="times circle icon"></i>
                    Supprimer
                </div>
                <div class="ui button" @click="$router.push({name: 'honoraireList'})">
                    <i class="times circle icon"></i>
                    Cancel
                </div>
            </div>
        </div>

        <div class="two column centered row">
            <div class="ten wide column">
                    <div class="ui form">
                  
                        <div class="field" :class="{'error': $v.selected_assurance.$error}">
                            <label>Compagnie </label>
                            <v-select  :options="assurance_list" :reduce="option => option._id" label="label" v-model="$v.selected_assurance.$model" />
                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.selected_assurance.$error">
                                <p><i class="info circle icon"></i> L'Compagnie est Obligatoire. </p>
                            </div>
                        </div>
                        <div class="field" :class="{'error': $v.selected_cadre_expertise.$error}">
                            <label>Cadre expertise </label>
                            <v-select  :options="cadre_expertise_list" v-model="$v.selected_cadre_expertise.$model" />
                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.selected_cadre_expertise.$error">
                                <p><i class="info circle icon"></i> Le cadre expertise est Obligatoire. </p>
                            </div>
                        </div>
                        <div class="field">
                            <label>Montant</label>
                            <input type="number" placeholder="Montant" v-model.number="$v.honoraire.montant.$model">
                        </div>
                    </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    export default {
        name: "honoraireCrud",
        props:{
            current_honoraire: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
          return{
              isEdit: false,
              honoraire: {
                  id_compagnie: '',
                  cadre_expertise: '',
                  montant: 0,
              },
              assurance_list: [],
              selected_assurance: '',
              selected_cadre_expertise:''
          }
        },
        validations: {
            honoraire: {
                montant: {required},
            },
            selected_assurance: {required},
            selected_cadre_expertise: {required},
        },
        computed:{
            cadre_expertise_list(){
                return this.$store.state.cadre_expertise;
            }
        },
        methods:{
            save(){  
                this.honoraire.id_compagnie = this.selected_assurance;
                this.honoraire.cadre_expertise = this.selected_cadre_expertise;
                log('save honoraire:', this.honoraire);

                this.$v.$touch();
                if (this.$v.$invalid)  return;

                let data = {
                    collection: this.$SHARED.collections.honoraire,
                    query: this.honoraire
                };
                if(this.isEdit){ // update
                    data.query = {
                        _id: this.honoraire._id
                    };
                    data.update = {'$set': this.honoraire};
                    data.remove_id = true
                }

                this.$store[this.isEdit ? 'requestUpdate' : 'requestInsert'](data).then(() => {
                    this.$router.push({name: 'honoraireList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.honoraire[this.isEdit ? 'update_succeeded' : 'create_succeeded']
                    });
                }, code => {
                    let message = this.$SHARED.messages.technical_error;
                    if(code === 7){
                        message = "Le honoraire " + this.$SHARED.messages["7"]
                    }

                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: message
                    });
                });
            },
            remove(){
                let data = {
                    collection: this.$SHARED.collections.honoraire,
                    query: {
                        _id: this.honoraire._id
                    }
                };
                this.$store.requestDelete(data).then(() => {
                    this.$router.push({name: 'honoraireList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.honoraire.delete_succeeded
                    });
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                });
            },
            getAssurances() {
                log('getAssurances');
                const data = {
                    collection: this.$SHARED.collections.assurance,
                    query: {},
                    options: {
                        page: 1,
                        limit: -1,
                        sort: {label: 1}
                    }
                };
                this.$store.requestFind(data).then(ar => {
                    this.assurance_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
        },
        mounted() {
            this.getAssurances();
            if(this.current_honoraire.hasOwnProperty('_id')){
                this.isEdit = true;
                this.honoraire = JSON.parse(JSON.stringify(this.current_honoraire));
                this.selected_assurance = JSON.parse(JSON.stringify(this.honoraire.id_compagnie));
                this.selected_cadre_expertise = JSON.parse(JSON.stringify(this.honoraire.cadre_expertise));
                delete this.honoraire.assurance_list;
            }
            
        }
    }
</script>

<style scoped>
    .border-bottom{
        border-bottom: 2px solid #767676;
    }
</style>